@import "~@angular/material/theming";
@import './rina-colors.scss';

$dark-theme-primary: mat-palette($rina-grayweb, 50, 100, 500, 900); // most widely used across all screen and components
$dark-theme-accent: mat-palette($rina-blue, 50, 100, 500, 900); // floating action color and interactive element
$dark-theme-foreground: mat-palette($rina-lightblue, 50, 100, 500, 900); // for text and icons
$dark-theme-warn: mat-palette($rina-warn, 50, 100, 500, 900);
$dark-theme: mat-dark-theme($dark-theme-primary, $dark-theme-accent, $dark-theme-foreground);

@function color-get($map, $key, $opacity) { // input palette, color number, opacity  --> output rgba
  $value: map-get($map, $key);
  @return rgba($value, $opacity);
}

.light-theme {
  .drawer {
    background-image: linear-gradient(color-get($dark-theme-accent, 500, 1), color-get($dark-theme-foreground, 500, 1));
  }

  .sidenav {
    background-image: linear-gradient(color-get($dark-theme-accent, 500, 1), color-get($dark-theme-foreground, 500, 1));
  }

  %page-toolbar {
    height: 48px;
    color: white;
    background: mat-color($rina-lightblue, 500);

    mat-nav-list mat-list-item,
    mat-list mat-list-item {
      color: white;
      &.active {
        color: mat-color($rina-darkblue, 500);
      }
    }
  }


  %rina-text {
    color: mat-color($rina-darkblue, 500, 0.7);

    &:hover {
      color: mat-color($rina-darkblue, 500, 1);
    }
  }

  .mat-tooltip {
    background: mat-color($rina-gray, 500, 0.9);
  }

  .mat-drawer-container {
    color: mat-color($rina-darkblue, 500, 0.7);
    background-color: mat-color($rina-grayweb, 500);
  }

  cube-sidemenu {
    $dark-theme-primary: mat-palette($rina-lightblue, 500, 100, 800);
    $dark-theme-accent: mat-palette($rina-darkblue, 500, 100, 800);
    $dark-theme-warn: mat-palette($rina-warn);
    $dark-theme: mat-dark-theme($dark-theme-primary, $dark-theme-accent, $dark-theme-warn);
    @include angular-material-theme($dark-theme);
    @include mat-list-theme($dark-theme);
  }

  cube-toolbar {
    .mat-toolbar {
      background: mat-color($rina-grayweb, 500);
      color: mat-color($rina-darkblue, 500);
    }
  }

  cube-grafana-container-module {
    .mat-toolbar.page-toolbar {
      @extend %page-toolbar;
    }
  }

  mat-form-field {
    &.mat-form-field-appearance-outline {
      .mat-form-field-outline {
        // color: mat-color($rina-blue, 500);
      }

      .mat-form-field-outline-thick {
        color: mat-color($rina-lightblue, 500);
      }
    }
  }

  cube-grafana-container-module {
    .mat-toolbar.page-toolbar {
      @extend %page-toolbar;
      height: 48px;
    }
  }
  cube-grafana-container-module {
    mat-card-header {
      background-color: mat-color($rina-blue, 500);

      .mat-card-title {
        color: rgba(255, 255, 255, 1);
      }
      .mat-card-subtitle {
        color: rgba(255, 255, 255, 0.85);
      }
      .mat-icon-button {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
