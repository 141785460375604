// Dark theme
@import "../../node_modules/@angular/material/theming";
/*
  $dark-primary: mat-palette($rina-darkgray, 500, 100, 800);
  $dark-accent:  mat-palette($rina-lightgray, 500, 100, 800);
  $dark-warn:    mat-palette($rina-grayweb);
*/
/*
$dark-primary: mat-palette($rina-darkgray, 500, 100, 800);
$dark-accent : mat-palette($rina-lightgray, 500, 100, 800);
$dark-warn : mat-palette($mat-red);
$dark-theme : mat-dark-theme($dark-primary, $dark-accent, $dark-warn);*/
.dark-theme {


  cube-no-content {
    &.color-primary {
      mat-icon,
      .title,
      .description {
        color: mat-color($rina-grayweb, 500);
      }
    }

    &.color-warn {
      mat-icon,
      .title,
      .description {
        color: mat-color($rina-warn, 500);
      }
    }
  }
}
